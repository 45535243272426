.info {
    overflow: hidden;
    position: relative;
    &__content {
        z-index: 1;
        position: relative;
        h2 {
            font-size: rem(45px);
            margin-bottom: 55px;
            @include mq($until: xxl) {
                font-size: rem(38px);
            }
            @include mq($until: xxl) {
                font-size: rem(34px);
                margin-bottom: 35px;
            }
            @include mq($until: sm) {
                font-size: rem(24px);
            }
        }
        p {
            font-size: rem(16px);
            line-height: rem(35px);
        }
        .row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 100px;
            align-items: center;
            .col-left, .col-right {
                margin: 0;
                padding: 0;
                max-width: unset;
            }
        }
    }
    &--cruises {
        padding-top: 80px;
        .info__content {
            text-align: center;
            p {
                margin: 0 auto;
                max-width: 1040px;
                line-height: rem(28px);
            }
        }
        .decor {
            width: 250px;
            top: 0;
            left: -50px;
            transform: rotate(-16deg);
            padding: 18px;
        }
    }
    &--routes {
        padding-top: 80px;
        padding-bottom: 260px;
        display: grid;
        grid-template-columns: auto minmax(300px, 600px);
        gap: 60px;
        @include mq($until: xxl) {
            grid-template-columns: auto 25vw;
        }
        @include mq($until: xl) {
            grid-template-columns: 1fr;
            .info__route {
                display: none;
            }
        }
        .info__content {
            h3 {
                font-size: rem(21px);
                margin-bottom: 25px;
            }
            p {
                margin: 0 auto;
                max-width: 1040px;
                line-height: rem(28px);
            }
        }
        .routes {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
            @include mq($until: md) {
                grid-template-columns: 1fr 1fr;
            }
            @include mq($until: 600px) {
                grid-template-columns: 1fr;
            }
            &__single {
                border: 1px solid var(--paletteColor5);
                padding: 30px;
                border-radius: 5px;
                transition: ease 0.3s all;
                &:hover {
                    border-color: var(--paletteColor1);
                    background-color: var(--paletteColor6);
                    svg {
                        background-color: var(--paletteColor1);
                        path {
                            fill: #fff;
                        }
                    }
                }
                &-top {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    align-items: center;
                    b {
                        font-size: rem(18px);
                        font-weight: 700;
                        text-transform: uppercase;
                        color: var(--paletteColor1);
                    }
                    p {
                        color: var(--paletteColor1);
                        font-style: italic;

                    }
                    svg {
                        background-color: var(--paletteColor6);
                        border-radius: 100%;
                        width: 63px;
                        height: 63px;
                        padding: 5px;
                        transition: ease 0.3s all;
                        path {
                            fill: var(--paletteColor1);
                            transition: ease 0.3s all;
                        }
                    }
                }
                &-list {
                    list-style: none;
                    counter-reset: numeric-counter;
                    margin-left: 0;
                    li {
                        list-style: none;
                        counter-increment: numeric-counter;
                        position: relative;
                        padding-left: 35px;
                        margin: 0;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        color: var(--paletteColor1);
                        line-height: 16px;
                        z-index: 1;
                        &::before {
                            content: counter(numeric-counter);
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            width: 27px;
                            height: 27px;
                            display: inline-block;
                            border: solid var(--paletteColor1) 2px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            font-weight: 600;
                            background-color: #fff;
                        }
                        &::after {
                            content: '';
                            width: 2px;
                            height: 100%;
                            background-color: var(--paletteColor1);
                            left: 12px;
                            top: 30px;
                            position: absolute;
                            z-index: -1;
                        }
                        &:last-of-type:after {
                            display: none;
                        }
                    }
                }
                & > p {
                    text-transform: lowercase;
                    color: var(--paletteColor1);
                    font-style: italic;
                }
            }
        }
        .decor {
            width: 400px;
            bottom: 80px;
            left: 50%;
        }
    }
    &--why {
        margin-top: 140px;
        padding-bottom: 60vh;
        background-position: top center;
        background-size: cover;
        @include mq($until: xl) {
            margin-top: 60px;
            padding-bottom: 180px;
        }
        @include mq($until: md) {
            padding-bottom: 100px;
        }
        .divider[data-divider*=top][data-divider*=gradient]:before {
            height: 50vh;
        }
        .info__content {
            text-align: center;
            p {
                margin: 0 auto;
                max-width: 1000px;
                line-height: rem(28px);
            }
            .icons-set {
                margin-top: 40vh;
                @include mq($until: xl) {
                    margin-top: 180px;
                }
                @include mq($until: md) {
                    margin-top: 80px;
                }
                @include mq($until: xs) {
                    grid-template-columns: 1fr 1fr;
                }
                @include mq($until: 400px) {
                    grid-template-columns: 1fr;
                }
                &__item {
                    background-color: rgba(255, 255, 255, 0.9);
                    border-radius: 5px;
                    padding: 40px;
                    @include mq($until: xl) {
                        padding: 30px;
                    }
                    @include mq($until: lg) {
                        padding: 20px;
                    }
                    @include mq($until: 400px) {
                        flex-direction: row;
                        text-align: left;
                    }
                    .image {
                        @include mq($until: 400px) {
                            min-width: 60px;
                            max-width: 60px;
                        }
                        svg {
                            width: 100%;
                            aspect-ratio: 1/1;
                            max-width: 150px;
                            @include mq($until: xl) {
                                max-width: 100px;
                            }
                            @include mq($until: md) {
                                max-width: 80px;
                            }
                        }
                    }
                    p {
                        font-size: rem(19px);
                        line-height: rem(26px);
                        color: var(--paletteColor1);
                    }
                }
            }
        }
    }
    &--cta {
        padding-top: 100px;
        padding-bottom: 180px;
        @include mq($until: md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .info__content {
            text-align: center;
            max-width: 900px;
            margin: 0 auto;
            a {
                margin-top: 40px;
            }
        }
        .decor {
            &:first-of-type {
                width: 240px;
                top: 50%;
                left: 5%;
                transform: translateY(-50%);
                @include mq($until: md) {
                    width: 180px;
                }        
                svg path {
                    fill: var(--paletteColor1);
                }
            }
            &:last-of-type {
                width: 270px;
                top: 50%;
                right: -75px;
                transform: translateY(-50%);
                @include mq($until: md) {
                    width: 180px;
                    right: -55px;
                }
                svg path {
                    stroke-width: 4;
                }
            }
        }
    }
    &--news {
        .catalog-list {
            gap: 100px;
            @include mq($until: xl) {
                gap: 80px;
            }    
            @include mq($until: lg) {
                grid-template-columns: 1fr;
                padding-left: 50px;
                padding-right: 50px;
                gap: 40px;
            }    
            @include mq($until: sm) {
                padding-left: 30px;
                padding-right: 30px;
            }    
            @include mq($until: xs) {
                padding-left: 20px;
                padding-right: 20px;
            }    
        }
        .catalog-card {
            position: relative;
            overflow: hidden;
            aspect-ratio: 4/3;
            justify-content: flex-end;
            width: 100%;
            @include mq($until: lg) {
                aspect-ratio: 16/9;
                width: 100%;
                &__excerpt, &__list {
                    display: none;
                }
            }
            @include mq($until: md) {
                aspect-ratio: unset;
            }
            &:hover {
                .catalog-card__footer {
                    bottom: 0;
                    opacity: 1;
                }
            }
            &.image-above .catalog-card__content {
                padding: 0 120px 80px 80px;
                @include mq($until: xl) {
                    padding: 0 120px 60px 60px;
                }
                @include mq($until: md) {
                    padding: 25px 30px 25px 30px;
                }
                @include mq($until: sm) {
                    padding: 15px 20px 15px 20px;
                }
            }
            &__image {
                width: auto;
                min-height: 100%;
                min-width: 100%;
                max-width: unset;
                @include mq($until: md) {
                    max-width: 100%;
                }

            }
            &__top {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include mq($until: md) {
                    position: relative;
                }
                [data-hover] {
                    display: inline;
                }
            }
            &__content {
                justify-content: flex-end;
                flex: 0;
                position: relative;
                h3 {
                    color: #fff;
                    margin-top: -20px;
                    font-size: rem(32px);
                    line-height: rem(37px);
                    margin-bottom: 0;
                    @include mq($until: xl) {
                        font-size: rem(24px);
                        line-height: rem(26px);
                    }
                    @include mq($until: md) {
                        margin-top: 0;
                        font-size: rem(20px);
                        line-height: rem(22px);
                    }
                    @include mq($until: sm) {
                        margin-top: 0;
                        font-size: rem(18px);
                        line-height: rem(20px);
                    }
                }
                p {
                    color: #fff;
                    font-size: rem(15px);
                }
                &:after {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    content: '';
                    background-color: var(--paletteColor1);
                    right: 80px;
                    top: 0;
                    opacity: 0.78;
                    z-index: -1;
                    @include mq($until: md) {
                        right: 0;
                        opacity: 1;
                    }
                }
            }
            &__footer {
                position: absolute;
                bottom: -40px;
                right: 0;
                opacity: 0;
                transition: ease 0.3s all;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 176.794'%3E%3Cpath d='M0,176.794H1920V85.432S1845.25,0,1535.572,0C1200.266,0,1094.587,118.372,761.42,118.372c-201.865,0-206.134-89.895-426.114-89.895C91.837,28.477,0,85.432,0,85.432Z' transform='translate(0)' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
                width: 100%;
                align-items: flex-end;
                background-size: cover;
                @include mq($until: md) {
                    display: none;
                }
                .button {
                    margin-right: 60px;
                    background-color: unset;
                    color: var(--paletteColor2);
                    font-weight: 700;
                    text-transform: lowercase;
                    text-decoration: underline;
                    &:hover {
                        background-color: unset;
                    }
                }
            }
        }
    }
    &--adventure {
        padding-top: 240px;
        padding-bottom: 180px;
        @include mq($until: lg) {
            // padding-bottom: 180px;
        }
        @include mq($until: md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .info__content {
            max-width: 1200px;
            margin: 0 auto;
            .row {
                grid-template-columns: 2fr 1fr;
                @include mq($until: lg) {
                    gap: 60px;
                }    
                @include mq($until: md) {
                    display: flex;
                    flex-direction: column-reverse;
                    text-align: center;
                    img {
                        max-width: 300px;
                    }
                }
                .col-left {
                    p:last-of-type {
                        text-align: right;
                        color: var(--paletteColor1);
                        padding-right: 30px;
                        @include mq($until: md) {
                            text-align: center;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .decor {
            &:first-of-type {
                width: 200px;
                top: 80px;
                left: -70px;
                transform: rotate(-9deg);
                padding: 5px;
                @include mq($until: md) {
                    width: 160px;
                    top: -30px;
                }
            }
            &:last-of-type {
                width: 400px;
                bottom: 40px;
                left: 10%;
                @include mq($until: md) {
                    width: 300px;
                    bottom: 0;
                    left: 15%;
                    transform: translateX(-15%) ;
                }

            }
        }
    }
    &--ships {
        padding-top: 180px;
        padding-bottom: 360px;
        @include mq($until: lg) {
            padding-bottom: 180px;
        }
        @include mq($until: md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .gallery {
            gap: 20px;
            @include mq($until: lg) {
                grid-template-columns: repeat(4,1fr);
            }
            @include mq($until: md) {
                grid-template-columns: repeat(2,1fr);
            }
            @include mq($until: xs) {
                grid-template-columns: 1fr;
            }
        }
        .row {
            @include mq($until: xxl) {
                align-items: start;
            }
            @include mq($until: lg) {
                grid-template-columns: 1fr;
                gap: 60px;
            }
            .col-right {
                @include mq($until: md) {
                    text-align: center;
                }
                a {
                    margin-top: 40px;
                    float: right;
                    padding-left: 50px;
                    padding-right: 50px;
                    @include mq($until: lg) {
                        margin-top: 20px;
                        float: unset;
                    }
                }
            }
        }
        .decor {
            &:first-of-type {
                width: 200px;
                top: 0;
                right: -40px;
                @include mq($until: md) {
                    width: 160px;
                    top: -30px;
                }
            }
            &:last-of-type {
                width: 200px;
                bottom: 120px;
                left: 50%;
                transform: rotate(-30deg) translateX(-50%);
                @include mq($until: md) {
                    width: 160px;
                    bottom: 180px;
                    transform: rotate(-30deg) translateX(-50%) ;
                }
            }
        }
    }
    .decor {
        opacity: 0.13;
        position: absolute;
        svg {
            width: 100%;
        }
    }
}
