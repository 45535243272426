.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 0;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
    @include mq($until: md) {
        padding-top: 0;
        padding-right: 0;
        min-height: unset;
        justify-content: center;
        &:not(.hero--small):not(.hero--rent) {
            .hero__content {
                padding-top: 40px;
                min-height: unset;
            }
            .hero__content:after {
                opacity: 1;
            }
            .divider[data-divider*=top] {
                transform: rotate(180deg);
                top: 1px;
                &:before {
                    background: linear-gradient(0deg, transparent 0%, var(--paletteColor1) 90%);
                }
            }            
        }
    }
    &--mobile {
        display: none;
        @include mq($until: md) {
            display: block;
        }
    }
    &--small {
        min-height: 40vh;
        padding-top: 0;
        align-items: center;
        @include mq($until: md) {
            min-height: unset;
        }
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            background-color: var(--paletteColor1);
            left: 0;
            top: 0;
            opacity: 0.78;
        }
        .hero__content {
            padding-top: 100px;
            padding-bottom: 100px;
            min-height: unset;
            align-items: flex-start;
            @include mq($until: md) {
                padding-top: 60px;
                padding-bottom: 60px;
            }
            &:after {
                display: none;
            }
            h1 {
                margin: 0;
                font-size: rem(45px);
                line-height: rem(47px);
                right: unset;
                top: unset;
                position: relative;
                left: unset;
                margin-bottom: 0;
                @include mq($until: lg){
                    font-size: rem(38px);
                    line-height: rem(40px);
                }
                @include mq($until: sm){
                    font-size: rem(28px);
                    line-height: rem(30px);
                }
            }
        }
        @include mq($until: lg){
            // aspect-ratio: 16/9;
            // height: unset;
            // padding-top: unset;
            // padding-bottom: unset;
        }
        @include mq($until: sm){
            // aspect-ratio: 4/3;
        }
    }
    &--rent {
        justify-content: flex-end;
        @include mq($until: lg) {
            min-height: unset;
            padding-top: 0;
            padding-left: 0;
        }
        .hero__content {
            padding-top: 140px;
            @include mq($until: lg) {
                padding-bottom: 80px;
                padding-top: 100px;
                text-align: center;
                align-items: center;
                min-height: 60vh;
                justify-content: center
            }
            h2 {
                color: #fff;
                margin-bottom: 0;
                font-size: rem(75px);
                line-height: rem(77px);
                font-weight: 700;
                position: absolute;
                top: rem(-34px);
                left: rem(-20px);
                max-width: 700px;
                @include mq($until: lg) {
                    font-size: rem(34px);
                    line-height: rem(37px);
                    position: relative;
                    top: unset;
                    left: unset;
                    max-width: unset;
                }
                @include mq($until: sm) {
                    font-size: rem(24px);
                    line-height: rem(26px);
                }
            }
            p {
                max-width: 550px;
                width: 100%;
                @include mq($until: lg) {
                    max-width: unset;
                }
                &:last-of-type {
                    text-align: right;
                    @include mq($until: lg) {
                        text-align: center;
                    }
                }
            }
            a {
                color: #fff;
                font-weight: 700;
            }
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        padding-bottom: 140px;
        padding-top: 90px;
        @include mq($until: md) {
            padding-top: 160px;
            min-height: 60vh;
            align-items: center;
            width: 100%;
        }
        @include mq($until: xs) {
            padding-bottom: 80px;
        }
        @include mq($until: 400px) {
            padding-bottom: 60px;
        }
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            background-color: var(--paletteColor1);
            left: 0;
            top: 0;
            opacity: 0.78;
            z-index: -1;

        }
        h1 {
            color: #fff;
            // margin-bottom: 0;
            // font-size: rem(108px);
            font-weight: 700;
            // position: absolute;
            // top: rem(-75px);
            // right: rem(-20px);
            font-size: 6rem;
            position: relative;
            top: -10.5rem;
            right: -1.25rem;
            margin-bottom: -9rem;
            margin-right: -8vw;
            @include mq($until: 1320px) {
                font-size: 4.6rem;
                right: -0.5rem;
                top: -9.5rem;
            }
            // @include mq($until: xl) {
            //     font-size: rem(85px);
            //     line-height: rem(87px);
            //     right: unset;
            //     left: 100px;
            //     top: rem(-55px);
            // }
            @include mq($until: md) {
                font-size: rem(44px);
                line-height: rem(47px);
                margin-bottom: 20px;
                margin-right: 0;
                position: relative;
                top: unset;
                left: unset;
                right: unset;
                max-width: unset;
                text-align: center;
            }
            @include mq($until: sm) {
                font-size: rem(24px);
                line-height: rem(26px);
            }
        }
        h2 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
        }
        p {
            color: #fff;
            &:first-of-type:not(.icons-set__item-text) {
                margin-top: 20px;
            }
        }
        a {
            // display: block;
            // align-self: unset;
            // margin-top: 20px;
        }
        .icons-set {
            gap: 0;
            @include mq($until: md) {
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
            }
            @include mq($until: xs) {
                grid-template-columns: repeat(2, 1fr);
                row-gap: 20px;
                a:nth-child(2) .icons-set__item:after {
                    display: none;
                }
            }
            @include mq($until: 420px) {
                grid-template-columns: 1fr;
                row-gap: 0;
                a:nth-child(2) .icons-set__item:after {
                    display: block;
                  }
            }
            a:last-of-type .icons-set__item:after {
                display: none;
            }
            &__item {
                padding: 10px 30px;
                position: relative;
                @include mq($until: 1320px) {
                    padding: 15px 20px;
                }
                @include mq($until: md) {
                    flex-direction: column;
                }
                @include mq($until: sm) {
                    padding: 20px 20px;
                }
                &:after {
                    width: 1px;
                    height: 100%;
                    content: '';
                    right: 0;
                    top: 0;
                    position: absolute;
                    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%);
                    @include mq($until: 420px) {
                        width: 100%;
                        height: 1px;
                        top: unset;
                        bottom: 0;
                        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%);
                    }
                }
                &:hover {
                    background-color: rgba(255, 255, 255, 0.25);
                }
                .image {
                    svg {
                        width:60px;
                        height: 60px;
                        @include mq($until: 1320px) {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
                &-text {
                    font-size: rem(18px);
                    line-height: rem(22px);
                    font-weight: 500;
                    @include mq($until: md) {
                        text-align: center;
                    }            
                }
            }
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }
    .divider {
        svg {
            max-height: 100px;
        }
    }
}
