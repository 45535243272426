.footer {
  background-color: var(--paletteColor4);
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  row-gap: 40px;
  column-gap: 40px;
  span, p, a, div, svg path {
    color: #fff;
    fill: #fff;
  }
  p {
    margin: 0;
  }
  svg {
    width: 24px;
    height: auto;
    max-width: unset;
  }
  .title {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .logo {
    img {
      height: auto;
    }
  }
  .social {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  .social svg {
    transition: ease 0.3s all;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .form span {
    color: $paragraph-color;
  }
  .form {
    background-color: #fff;
    padding: 40px;
    max-width: 700px;
    margin-bottom: 0;
    @include mq($until: lg){
      max-width: 100%;
    }
  }
  .nav {
    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center
    }
    &.space {
      .header__menuList {
        width: 100%;
        justify-content: space-between;
        column-gap: 10px;
      }
      .header__menuLink {
        padding: 20px 0px;
      }    
    }
    .header__menuList {
      padding: 0;
    }
    .header__menuLink:active, .header__menuLink--active, .header__menuLink--open, .header__menuLink:hover {
      color: #fff;
    }
  }
  .block {
    padding: 40px;
    background: lighten($color-footer, 4);
    transition: ease 0.3s all;
  }
  .contact {
    .list {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      a {
        display: flex;
        column-gap: 15px;
        align-items: center;
      }
    }
  }
  &.icons-bg {
    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
      background: lighten($color-footer, 4);
      transition: ease 0.3s all;
    }
    a:hover svg {
      background-color: $color-extra;
    }
    .block svg {
      background: lighten($color-footer, 10);
    }
  }
  &.icons-border {
    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
      border: 1px solid lighten($color-footer, 10);
      transition: ease 0.3s all;
    }
    a:hover svg {
      border-color: $color-extra;
    }
  }



  &.footer01 {
    justify-content: space-between;
    &.grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @include mq($until: xl){
        grid-template-columns: 1fr 1fr;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    @include mq($until: xl){
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
    }
  }



  &.footer02 {
    display: grid;
    grid-template-columns: minmax(200px, 350px) minmax(200px, 350px) auto;
    @include mq($until: xl){
      grid-template-columns: 1fr 1fr;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include mq($until: xl){
        justify-content: flex-start;
      }  
      .title {
        margin-bottom: 0;
      }
    }
  }

  &.footer03 {
    display: grid;
    grid-template-columns: minmax(300px, 50%) auto;
    align-items: start;
    @include mq($until: md){
      grid-template-columns: 1fr;
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    .contact {
      row-gap: 40px;
      column-gap: 40px;
      display: grid;
      align-items: start;
      @include mq($until: md){
        //order: 2;
        grid-template-columns: 1fr 1fr;
        align-items: stretch;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }  
    }
    .social {
      justify-content: flex-end;
    }
    @include mq($until: md){
      .form {
        order: 2;
      }
    }
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 40px;
      @include mq($until: md){
        align-items: flex-start;
      }
      .title {
        margin-bottom: 0;
      }
    }
  }



  &.footer04 {
    display: grid;
    grid-template-columns: minmax(300px, 50%) auto;
    @include mq($until: xl){
      grid-template-columns: auto 400px;
    }
    @include mq($until: lg){
      grid-template-columns: 1fr;
    }
    .block:nth-child(2),.block:nth-child(3) {
      background-color: transparent;
    }
    @include mq($until: sm){
      .block:nth-child(3) {
        background: lighten($color-footer, 4);
      }
      .block:nth-child(4) {
        background-color: transparent;
      }
    }
    .left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      gap: 40px;
      &.blocks {
        gap: 0;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 40px;
      .title {
        margin-bottom: 0;
      }
    }
  }



  &.footer05 {
    flex-direction: column;
    .top {
      display: grid;
      grid-template-columns: minmax(200px, 25%) auto;
      align-items: center;
      @include mq($until: md){
        grid-template-columns: 1fr;
        row-gap: 40px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      column-gap: 40px;  
      padding-top: 60px;
      border-top: 1px solid lighten($color-main, 3);
      @include mq($until: xl){
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
      }
      @include mq($until: sm){
        grid-template-columns: 1fr;
      }
      &.grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: xl){
          grid-template-columns: 1fr 1fr;
        }
        @include mq($until: sm){
          grid-template-columns: 1fr;
        }  
      }
      .social {
        margin-top: 20px;
      }
    }
  }
  &.footer06 {
    justify-content: space-between;
    align-items: center;
    @include mq($until: md) {
      display: grid;
      grid-template-columns: 200px 1fr;
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;
      .contact .list {
        align-items: center;
      }
    }
    .captain {
      img {
        width: 200px;
      }
    }
    .logo {
      margin-bottom: 30px;
      display: block;
    }
    .list {
      margin-top: 30px;
    }
    .icons-set {
      gap: 0;
      @include mq($until: md) {
        display: flex;
      }
      @include mq($until: 400px) {
        flex-direction: column;
      }
      a:last-of-type .icons-set__item:after {
        display: none;
      }

      &__item {
        padding: 10px 30px;
        position: relative;
        @include mq($until: 1320px) {
          padding: 15px 20px;
          flex-direction: column;
          text-align: center;
        }
        @include mq($until: md) {
          flex-direction: row;
        }
        @include mq($until: xs) {
          flex-direction: column;
        }
        &:after {
          width: 1px;
          height: 100%;
          content: '';
          right: 0;
          top: 0;
          position: absolute;
          background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%);
          @include mq($until: 400px) {
            width: 100%;
            height: 1px;
            top: unset;
            bottom: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%);
          }
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        .image svg {
          width: 60px;
          height: 60px;
          @include mq($until: xl) {
            width: 40px;
            height: 40px;
          }
        }
        &-text {
          font-size: rem(18px);
          line-height: rem(22px);
          font-weight: 500;
          @include mq($until: md) {
            br {
              display: inline;
              content: ' ';
              padding: 0 3px;
            }
            @include mq($until: sm) {
              br {
                display: block;
                content: none;
                padding: 0;
              }
            }
          }
        }
      }
    }
    .right { 
      row-gap: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      flex: 1;
      @include mq($until: md) {
        grid-column: 1 / -1;
        order: -1;
        align-items: center;
      }
    }
  }
}


.footer-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    @include mq($until: md){
      margin-bottom: 54px;
    }  
    @include mq($until: xs){
      flex-direction: column;
      row-gap: 10px;
    }
    a, span, p, div {
      color: #4E5360;
      font-size: 14px;
    }
    a:hover {
      color: $color-main;
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}

.footer-mobile {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--buttonBgInitial);
  justify-content: center;
  z-index: 9;
  @include mq($until: md){
    display: flex;
  }  
  a {
    padding: 14px 12px;
    color: #fff;
    display: flex;
    align-items: center;
    transition: ease 0.3s all;
    @include mq($until: 620px){
      &:last-of-type {
        display: none;
      }
    }
    @include mq($until: 480px){
      &:nth-child(2) {
        svg {
          margin-right: 0;
        }    
        span {
          display: none;
        }
      }
    }
    @include mq($until: 340px){
      &:nth-child(1) {
        svg {
          margin-right: 0;
        }    
        span {
          display: none;
        }
      }
    }
    &:hover {
      background-color: var(--buttonBgHover);
    }
    span {
      color: #fff;
      font-size: 16px;
    }
    svg {
      margin-right: 10px;
      fill: #fff;
      width: 26px;
      height: 26px;
    }
  }
}
