html, body {
    scroll-behavior: smooth;
}
.scrolltop {
    display: none;
    a.top {
        position: fixed;
        display: flex;
        align-items: center;
        bottom: 10px;      
        right: 10px; 
        width: 45px;
        height: 45px;
        padding: 0 10px;
        z-index: 9;
        transition: ease 0.3s all;
        @include mq($until: md){
            right: 4px;
            bottom: 4px;
        }
        &.button--xs {
            width: 35px;
            height: 35px;
        }
        &.button--sm {
            width: 40px;
            height: 40px;
        }
        &.button--lg {
            width: 50px;
            height: 50px;
        }
        &.button--xl {
            width: 55px;
            height: 55px;
        }
        svg {
            width: 100%;
            height: auto;
        }
    }
}
