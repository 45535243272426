.features {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .icons-set {
            margin-top: 60px;
        }
    }
}

.features2 {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    display: flex;
    column-gap: 80px;
    &__content {
        position: relative;
        z-index: 1;
        align-self: center;
        h2 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .icons-set {
            margin-top: 60px;
        }
    }
    &__image {
        flex: 1;
        position: relative;
        overflow: hidden;
        .divider  {
            left: -0.5%;
            width: 101%;
        }
    }
}

.features3 {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    display: flex;
    column-gap: 80px;
    &__content {
        position: relative;
        z-index: 1;
        align-self: center;
        h2 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .icons-set {
            margin-top: 60px;
        }
        .icons-set .icons-set__item:hover {
            span, h4 {
                color: $paragraph-color;
            }
        }
    }
    &__image {
        flex: 1;
        position: relative;
        overflow: hidden;
        .divider  {
            left: -0.5%;
            width: 101%;
        }
    }
}

.features4 {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            color: #fff;
        }
        p {
            text-align: center;
            color: #fff;
        }
        .icons-set {
            margin-top: 60px;
            &__item {
                border-color: rgba(255,255,255, 0.1);
                img {
                    background-color: #fff;
                }
                h4, span {
                    color: #fff;
                }
                &:hover {
                    border-color: #fff;
                }
            }
        }
    }
}

.features5 {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            color: #fff;
        }
        p {
            text-align: center;
            color: #fff;
        }
        .icons-set {
            margin-top: 60px;
            max-width: 600px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            &__item {
                border-color: rgba(255,255,255, 0.1);
                img {
                    background-color: #fff;
                }
                h4, span {
                    color: #fff;
                }
                &:hover {
                    border-color: #fff;
                }
            }
        }
    }
}

.features6 {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
        }
        p {
            text-align: center;
        }
        .icons-set {
            margin-top: 60px;
            &__item {
                padding: 0;
                row-gap: 0;
                img {
                    border-radius: 10px 10px 0 0;
                    width: 100%;
                }
                &-content {
                    padding: 20px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}
