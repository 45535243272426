.separator {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    &.line {
        border-top: 1px solid #DADADA;
    }
    &.thick {
        border-top: 4px solid #DADADA;
    }
    &.dots {
        border-top: 5px dotted #DADADA;
    }
    &.dash {
        border-top: 2px dashed #DADADA;
    }
    &.double {
        border-top: 4px double #DADADA;
    }
    &.line2 {
        height: 2px;
        background: linear-gradient(90deg, transparent 0%, #DADADA 50%, transparent 100%);
    }
    &.diamond  {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg transform='translate(-424 -3334)'%3E%3Crect width='10' height='10' transform='translate(426.929 3344) rotate(-45)' fill='%23DADADA'/%3E%3C/g%3E%3C/svg%3E%0A");
        height: 14px;
        background-size: contain;
    }
    &.triangle  {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg transform='translate(-655 -3256)'%3E%3Cpath d='M5,0l5,8.333H0Z' transform='translate(660 3261.833)' fill='%23DADADA'/%3E%3C/g%3E%3C/svg%3E%0A");
        height: 20px;
        background-size: contain;
    }
    &.saw  {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg transform='translate(-655 -3256)'%3E%3Cpath d='M657.417,3286.468l10.341-8.218,9.659,8.218v1.782l-9.659-8.218-10.341,8.218Z' transform='translate(-2.417 -16.833)' fill='%23DADADA'/%3E%3C/g%3E%3C/svg%3E%0A");
        height: 20px;
        background-size: contain;
        background-position: center;
    }
    &.m1 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &.m2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    &.m3 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    &.m4 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    &.m5 {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &.m6 {
        margin-top: 90px;
        margin-bottom: 90px;
    }
    &.margin-top {
        margin-bottom: 0;
    }
    &.margin-bottom {
        margin-top: 0;
    }
    &.nomargin {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
    }
    &.h-desktop {
        display: none;
    }
	@include mq($until: md) {
        &.h-tablet {
            display: none;
        }
    }
	@include mq($until: sm) {
        &.h-mobile {
            display: none;
        }
    }
    &.s-desktop {
        display: inline-block;
    }
    &.s-tablet {
        display: none;
        @include mq($until: md) {
            display: inline-block;
        }
    }
    &.s-mobile {
        display: none;
        @include mq($until: sm) {
            display: inline-block;
        }
    }
}
