.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(50px);

  :focus{outline: none;}

  &__group {
    display: flex;
    column-gap: 20px;
    @include mq($until: xs){
      flex-direction: column;
    }
    .form__row {
      margin-top: 20px;
      width: 100%;
    }
  }

  &__row {
    position: relative;
    margin-top: 30px;
  }
  &__startdate {
    font-weight: 700;
  }

  &__data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 20px;
    @include mq($until: xl){
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: md){
      grid-template-columns: 100%;
    }
  }
  &__tickets {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 20px;
    @include mq($until: xxl){
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: md){
      grid-template-columns: 100%;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    column-gap: 10px;
    input {
      max-width: 80px;
    }
  }

  &__input {
    margin-top: 5px;
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #505052;
    background-color: transparent;
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    &--checkbox{
      margin: 0 5px 0 0 ;
    }
    &--textarea{
      margin-bottom: -7px;
      min-height: 50px;
    }

    & ~ .form__border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-main;
      transition: 0.3s;
    }

    &:focus ~ .form__border {
      width: 100%;
      transition: 0.3s;
    }

    &:focus ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

  }

  &__label  {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0px;
    color: #505052;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 10px;
  }

  .submitSection.inline {
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin-top: 20px;
    @include mq($until: xs){
      flex-direction: column;
      align-items: unset;
      row-gap: 20px;
    }
    .form__rodo {
      margin: 0;
    }
  }

  &__rodo {
    width: 100%;
    margin-bottom: rem(30px);
    margin-top: rem(15px);
    align-items: center;
    justify-content: flex-start;
    label {
      display: grid;
      grid-template-columns: 50px 1fr;
      column-gap: 20px;
      cursor: pointer;
      align-items: center;
    }
    span {
      font-size: rem(13px);
    }
    input {display: none;}
    .checker {
      background: rgba(159, 167, 183, 0.2);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1/1;
      transition: ease 0.3s all;
      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='150.657' height='100.657' viewBox='0 0 150.657 100.657'%3E%3Cg transform='translate(-387.672 -497.672)'%3E%3Cline x2='52' y2='52' transform='translate(390.5 540.5)' fill='none' stroke='%23FFF' stroke-width='8'/%3E%3Cline y1='95' x2='95' transform='translate(440.5 500.5)' fill='none' stroke='%23FFF' stroke-width='8'/%3E%3C/g%3E%3C/svg%3E%0A");
        width: 30px;
        height: 30px;
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: ease 0.3s opacity;
      }
    }
    .checker {
      background: rgba(159, 167, 183, 0.2);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      border-radius: 5px;
    }
    input:checked ~ .checker {
      background: $color-main;
    }
    input:checked ~ .checker:after {
      opacity: 1;
    }
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }

}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert{
  h4{
    margin-top: 15px;
    font-size: rem(20px);
  }
}
