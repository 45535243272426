.header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  min-height: 100px;
  background-color: #fff;
  z-index: 1001;
  column-gap: 20px;

  &--absolute {
    position: absolute;
    width: 100%;
    border-bottom: none;
    background: transparent;
    .header__logo--dark {
      display: none;
    }
    .header__menuLink {
      color: #000;
      &:active, &--active, &--open, &:hover {
        color: var(--paletteColor2);
      }
    }
    .header__submenu .header__menuLink {
      color: #fff;
      &:active, &--active, &--open, &:hover {
        color: #fff;
      }
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      width: 100%;
      background-color: $color-header;
      .header__logo--dark {
        display: block;
      }
      .header__logo--light {
        display: none;
      }
      .header__menuLink {
        color: #000;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
      .header__submenu .header__menuLink {
        color: #fff;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    // &--dark {
    //   display: none;
    // }
    img {
      height: auto;
      @include mq($until: lg) {
        width: 13vw;
      }
      @include mq($until: md) {
        width: 140px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    column-gap: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    padding-left: 20px;
    height: 100%;
    @include mq($until: 1620px) {
      column-gap: 20px;
    }
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
    @include mq($until: 1400px) {
      flex-wrap: wrap;
      padding: 10px 0;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    @include mq($until: 1820px) {
      &:first-of-type {
        display: none;
      }
    }
    &::before {
      display: none;
    }
    &:first-of-type {
      white-space: nowrap;
    }
  }

  &__menuLink {
    color: #000;
    padding: 20px 15px;
    display: block;
    font-weight: 500;
    font-size: rem(17px);
    text-align: center;
    @include mq($until: 1600px) {
      font-size: rem(16px);
      padding: 20px 10px;
    }
    @include mq($until: 1400px) {
      padding: 5px 10px;
    }

    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: var(--paletteColor2);
    }
  }
}
